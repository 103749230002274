import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/website/website/src/mdx/templates/default/MDXDefaultLayout.jsx";
import { Link } from '~/src/components/Link';
import { CodePen } from "@uc-common/codepen";
import { Notification } from '~/src/components/Typography';
import schema from '~/src/pages/guides/ld-json-schemas/browse-uploaded.json';
export const frontmatter = {
  title: 'How to let users browse their previously uploaded files in the File Uploader',
  description: 'Uploadcare is a powerful tool even when it comes to the details. Learn more about how to browse the ' + 'previously uploaded files and make use of this advanced feature in minutes with this article.',
  header: 'How to let users browse their previously uploaded files in the File Uploader',
  schema
};
export const _frontmatter = {};
const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p>{`You might want to allow users to see and manage the files they uploaded.`}</p>
    <p>{`Uploadcare doesn’t store relations between users and files, and doesn’t allow the File Uploader to list all files in the project, for security reasons.`}</p>
    <p>{`However, there are two ways you can handle the file-to-user relations.`}</p>
    <h2 {...{
      "id": "local-storage"
    }}><a parentName="h2" {...{
        "href": "#local-storage"
      }}>{`Local storage`}</a></h2>
    <p>{`You can store the list of previously uploaded files in the browser’s local storage of your user.`}</p>
    <Notification mdxType="Notification">
  The users won’t be able to choose their files that were uploaded from other devices.
    </Notification>
    <p>{`This solution won’t require any support or maintenance on your part.`}</p>
    <p>{`Take a look at this example of a second implementation that uses the
browser’s local storage:`}</p>
    {
      /* https://codepen.io/uploadcare/pen/JqNQaM */
    }
    <CodePen id="JqNQaM" title="Uploaded files browser example" mdxType="CodePen" />
    <h3 {...{
      "id": "suggestions-from-our-engineers"
    }}><a parentName="h3" {...{
        "href": "#suggestions-from-our-engineers"
      }}>{`Suggestions from our engineers`}</a></h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Separate file lists by the project’s public key.
Every project has its own public key. If you use file uploaders with two or more Uploadcare projects, the user will see only the files from the current project.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Limit the number of files in the list. In this case, only the last 50 files are shown.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Use an `}<code parentName="p" {...{
            "className": "lang-text"
          }}>{`images-only`}</code>{` flag to show image files only.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Make sure users can use their file upload history to remove files.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "on-the-server-side"
    }}><a parentName="h2" {...{
        "href": "#on-the-server-side"
      }}>{`On the server side`}</a></h2>
    <p>{`Store information on your side, and load the list of user files in the uploader widget by making AJAX requests to your server.`}</p>
    <p>{`This is a relatively complex way, because you’re going to require storage space, as well as an endpoint that will return the list of files from this storage. This implementation heavily depends on your project’s tech stack.`}</p>
    <p>{`If you’re not sure which way to go, be sure to contact our friendly Support engineers at `}<Link to="mailto:help@uploadcare.com" target="_blank" mdxType="Link"><a parentName="p" {...{
          "href": "mailto:help@uploadcare.com"
        }}>{`help@uploadcare.com`}</a></Link>{`!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      